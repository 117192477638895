import ApiService from '@/services/ApiService';

class CompanyService {
  static async putUpgradePending(pendingUpgrade) {
    const composedURL = `/api3/company/upgrade_pending`;
    const requestBody = pendingUpgrade;
    const response = await ApiService.put(composedURL, requestBody);
    return response;
  }
}

export default CompanyService;
