import { createSlice } from '@reduxjs/toolkit';
import {
  getDevelopmentFiles,
  getDevelopmentSents,
  getFichaInfoUrl,
  getFiles,
  getHighLightContacts,
  getQuickDevData,
  getQuickOpenNaventData,
  getQuickPropData,
  getSents,
  isPropOrDevStarred,
  starDevelopment,
  starProperty,
  toggleFavourite,
  toggleFavouriteDevelopment,
} from './services';

const initialState = {
  versionFile: 'edited',
  networkId: undefined,
  buttonChatDisabled: false,
  show: false,
  isDevelopment: false,
  isOpenNavent: false,
  isContact: false,
  starredSingledContact: false,
  id: null,
  fichaInfo: {
    url: '',
    loading: false,
  },
  kebabId: 1,
  highlight: {
    loading: false,
    starLoading: false,
    contacts: [],
    highlightContacts: [],
  },
  quickData: { loading: false, data: false },
  sents: { loading: false, data: false },
  files: { loading: false, data: false },
  developmentFiles: { loading: false, data: false },
  active: true,
};

export const QuickDisplaySlice = createSlice({
  name: 'quickDisplay',
  initialState,
  reducers: {
    showQuickDisplay(state, actions) {
      state.show = Boolean(actions.payload);

      const {
        id,
        development,
        openNavent,
        contact,
        networkId,
        buttonChatDisabled,
      } = actions.payload;

      state.buttonChatDisabled = buttonChatDisabled;
      state.isDevelopment = development;
      state.networkId = networkId;
      state.isOpenNavent = openNavent;
      state.isContact = contact;
      state.id = id;

      if (!state.show) {
        Object.assign(state, initialState);
      }
    },

    changeId(state, actions) {
      const { id, development } = actions.payload;

      const resetObject = {
        ...initialState,
        show: true,
        isDevelopment: development,
      };

      Object.assign(state, resetObject);

      state.id = id;
    },

    resetData(state, actions) {
      Object.assign(state[actions.payload], initialState[actions.payload]);
    },

    resetStarredContact(state) {
      Object.assign(state.highlight, {
        ...initialState.highlight,
        highlightContacts: state.highlight.highlightContacts,
      });
    },

    toggleKebab(state, actions) {
      state.kebabId = actions.payload;
    },

    toggleFileData(state, actions) {
      const { data, edited, original } = state.quickData;

      state.versionFile = actions.payload;
      state.quickData.data =
        actions.payload === 'edited' ? { ...data, ...edited } : original;
    },

    addHighlightContact(state, actions) {
      const exist = state.highlight.highlightContacts.find(
        (item) => item.id === actions.payload.id
      );

      if (!exist) {
        state.highlight.highlightContacts.push(actions.payload);
      } else {
        state.highlight.highlightContacts =
          state.highlight.highlightContacts.filter(
            (item) => item.id !== actions.payload.id
          );
      }
    },
  },
  extraReducers: (builder) => {
    builder // Get ficha info url
      .addCase(getFichaInfoUrl.pending, (state) => {
        state.fichaInfo.loading = true;
      })
      .addCase(getFichaInfoUrl.fulfilled, (state, action) => {
        state.fichaInfo.loading = false;
        state.fichaInfo.url = action.payload.ficha_info_url;
      });

    builder // Get contacts
      .addCase(getHighLightContacts.pending, (state) => {
        state.highlight.loading = true;
      })
      .addCase(getHighLightContacts.fulfilled, (state, action) => {
        state.highlight.loading = false;

        state.highlight.contacts =
          action.payload.contacts.length > 0
            ? action.payload.contacts
            : [{ noData: 'NoResultsFound' }];
      });

    builder // Get quickDisplay prop data
      .addCase(getQuickPropData.pending, (state) => {
        state.quickData.loading = true;
      })
      .addCase(getQuickPropData.fulfilled, (state, action) => {
        state.quickData.loading = false;
        state.quickData = action.payload;

        const { edited, data, active } = action.payload;
        state.quickData.original = data;
        state.active = active || state.isOpenNavent;

        const filterOperations = (sale, rent, temporary) => {
          const customOp = {};

          if (sale && data.operations.Sale) {
            customOp.Sale = data.operations.Sale;
          }

          if (rent && data.operations.Rent) {
            customOp.Rent = data.operations.Rent;
          }

          if (temporary && data.operations.Temporary) {
            customOp.Temporary = data.operations.Temporary;
          }

          return customOp;
        };

        state.versionFile = edited?.has_edition ? 'edited' : 'original';

        if (edited?.has_edition) {
          const cutomEdited = {
            description: edited.custom_description,
            address: edited.custom_title,
            pictures: edited.pictures,
            operations: filterOperations(
              edited.show_sale,
              edited.show_rent,
              edited.show_temporary_rent
            ),
            occupation: edited.show_temporary_rent ? data.occupation : [],
            temporary: edited.show_temporary_rent ? data.temporary : null,
            has_edition: edited.has_edition,
          };

          state.quickData.edited = cutomEdited;
          state.quickData.data =
            state.versionFile === 'edited' ? { ...data, ...cutomEdited } : data;
        }
      });

    builder // Get quickDisplay development data
      .addCase(getQuickDevData.pending, (state) => {
        state.quickData.loading = true;
      })
      .addCase(getQuickDevData.fulfilled, (state, action) => {
        state.quickData.loading = false;
        state.quickData = action.payload;
        const { active } = action.payload;
        state.active = active;
      });

    builder // Get quickDisplay openNavent data
      .addCase(getQuickOpenNaventData.pending, (state) => {
        state.quickData.loading = true;
      })
      .addCase(getQuickOpenNaventData.fulfilled, (state, action) => {
        state.quickData.loading = false;
        state.quickData = action.payload;
      });

    builder // Get sents prperty
      .addCase(getSents.pending, (state) => {
        state.sents.loading = true;
      })
      .addCase(getSents.fulfilled, (state, action) => {
        state.sents.loading = false;
        state.sents.data = action.payload.total_sents > 0 && action.payload;
      });

    builder // Get sents development
      .addCase(getDevelopmentSents.pending, (state) => {
        state.sents.loading = true;
      })
      .addCase(getDevelopmentSents.fulfilled, (state, action) => {
        state.sents.loading = false;
        state.sents.data = action.payload.total_sents > 0 && action.payload;
      });

    builder // Get files property
      .addCase(getFiles.pending, (state) => {
        state.files.loading = true;
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        state.files.loading = false;
        state.files.data =
          action.payload.files.length > 0 ? action.payload : false;
      });

    builder // Get files development
      .addCase(getDevelopmentFiles.pending, (state) => {
        state.developmentFiles.loading = true;
      })
      .addCase(getDevelopmentFiles.fulfilled, (state, action) => {
        state.developmentFiles.loading = false;
        state.developmentFiles.data =
          action.payload.files.length > 0 ? action.payload : false;
      });

    builder // star property
      .addCase(starProperty.pending, (state) => {
        state.highlight.starLoading = true;

        if (state.isContact) {
          state.starredSingledContact = true;
        }
      })
      .addCase(starProperty.fulfilled, (state) => {
        state.highlight.starLoading = false;
      });

    builder // star development
      .addCase(starDevelopment.pending, (state) => {
        state.highlight.starLoading = true;

        if (state.isContact) {
          state.starredSingledContact = true;
        }
      })
      .addCase(starDevelopment.fulfilled, (state) => {
        state.highlight.starLoading = false;
      });

    builder // toggle favourite property
      .addCase(toggleFavourite.fulfilled, (state) => {
        state.quickData.favourite = !state.quickData.favourite;
      });

    builder // toggle favourite development
      .addCase(toggleFavouriteDevelopment.fulfilled, (state) => {
        state.quickData.favourite = !state.quickData.favourite;
      });

    builder // is Prop Or Dev Starred
      .addCase(isPropOrDevStarred.fulfilled, (state, action) => {
        state.starredSingledContact = action.payload;
      });
  },
});

export const {
  showQuickDisplay,
  resetData,
  toggleKebab,
  addHighlightContact,
  changeId,
  toggleFileData,
  resetStarredContact,
} = QuickDisplaySlice.actions;

export default QuickDisplaySlice.reducer;
