import ApiService from '@/services/ApiService';

class TemplatesService {
  static async getTemplates() {
    const composedURL = `/api3/websites/templates`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default TemplatesService;
