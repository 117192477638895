import ApiService from '@/services/ApiService';

class PropertiesService {
  static async exportToPdf(ids) {
    const composedURL = `/api3/properties/export_to_pdf?ids=${ids}&pdf_type=1`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getPagesForExportPdf() {
    const composedURL = `/api3/properties/pdf_types`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async exportToXls(ids) {
    const listConcat = `%5B${ids.toString().replaceAll(',', '%2C')}%5D`;
    const composedURL = `/api3/properties/export_to_xls?ids=${listConcat}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDefaultValues() {
    const composedURL = `/api3/properties/default_values`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getAllowedTagGroups(propertyTypeId) {
    const composedURL = `/api3/properties/get_allowed_tag_groups?property_type_id=${propertyTypeId}`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}
export default PropertiesService;
